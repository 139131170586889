import React from "react";
import { FaTrash } from "react-icons/fa";

import { Checkbox } from "../../Checkbox";
import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableHeadRow } from "../../TableHeadRow";
import { TableRow } from "../../TableRowTs";
import { TableRowActions } from "../../TableRowActions";

import { CONSTANTS } from "../../../common/constants";
import { TransferExtended, TransferProductUpdate } from "../../../backend-v3/transfers";

type Props = {
  deleteTransferProducts: (tpIds: number[]) => void;
  updateTransferProduct: (id: number, data: TransferProductUpdate) => void;
  transfer: TransferExtended;
};

const TransferTable = ({ deleteTransferProducts, transfer, updateTransferProduct }: Props) => {
  const isDraft = transfer.transfer.status === "draft";
  const isFulfilled = transfer.transfer.status === "fulfilled";
  const isReceived = transfer.transfer.status === "done";

  return (
    <Table
      bulkAction={!isReceived ? deleteTransferProducts : undefined}
      childIds={transfer.products.map(({ id }) => id)}
      className="mt-6 overflow-hidden"
      emptyState={!transfer.products.length && "No products yet..."}
      renderHead={({ bulkChecked, onBulkChange }) => (
        <TableHeadRow>
          {!isReceived && (
            <TableHeadCell
              slim
              width="4"
              value={
                <Checkbox
                  checked={bulkChecked.length >= transfer.products.length}
                  disabled={!transfer.products.length}
                  onChange={() => onBulkChange("all", bulkChecked.length >= transfer.products.length)}
                />
              }
            />
          )}
          <TableHeadCell value="Product" width="1/3" />
          <TableHeadCell value="SKU" width="32" />
          <TableHeadCell value="Origin stock" width="24" />
          <TableHeadCell value="Destination stock" width="24" />
          <TableHeadCell value="Destination On Order" width="24" />
          <TableHeadCell value="Destination Sold (90 days)" />
          <TableHeadCell value="Destination % Days In Stock (90 days)" />
          <TableHeadCell value={`Qty${!isDraft ? " Requested" : ""}`} />
          {!isDraft && <TableHeadCell value="Qty Fulfilled" />}
          {(isFulfilled || isReceived) && <TableHeadCell value="Qty Received" />}
          {!isReceived && <TableHeadCell alignment="right" width="32" value="Actions" />}
        </TableHeadRow>
      )}
      renderBody={({ bulkChecked, onBulkChange }) => (
        <>
          {transfer.products.map(
            ({
              id,
              stock_at_destination,
              stock_at_origin,
              qty_requested,
              qty_fulfilled,
              qty_received,
              inventory_days_available,
              sales_aggregate,
              orders_in_transfer,
              product_variant: { sku, url, full_title },
            }) => (
              <TableRow key={id}>
                {!isReceived && (
                  <TableCell
                    slim
                    width="4"
                    value={
                      <Checkbox
                        checked={bulkChecked.includes(id)}
                        onChange={() => onBulkChange(id, bulkChecked.includes(id))}
                        tabIndex="-1"
                      />
                    }
                  />
                )}
                <TableCell
                  width="1/3"
                  value={{
                    type: "string",
                    initial: full_title,
                  }}
                  url={url}
                />
                <TableCell
                  width="32"
                  capitalize={false}
                  value={{
                    type: "string",
                    initial: sku,
                  }}
                />
                <TableCell
                  width="24"
                  value={{
                    type: "number",
                    initial: stock_at_destination,
                  }}
                />

                <TableCell
                  width="24"
                  value={{
                    type: "number",
                    initial: stock_at_origin,
                  }}
                />

                <TableCell
                  width="24"
                  value={{
                    type: "number",
                    initial: orders_in_transfer,
                  }}
                />

                <TableCell
                  value={{
                    type: "number",
                    initial: sales_aggregate,
                  }}
                />
                <TableCell
                  value={{
                    type: "number",
                    initial: inventory_days_available,
                  }}
                />

                <TableCell
                  value={{
                    type: "number",
                    initial: qty_requested,
                    positiveOnly: true,
                  }}
                  onChange={isDraft ? value => updateTransferProduct(id, { qty_requested: Number(value) }) : undefined}
                />
                {!isDraft && (
                  <TableCell
                    value={{
                      type: "number",
                      initial: qty_fulfilled,
                      positiveOnly: true,
                    }}
                    onChange={
                      !isReceived && !isFulfilled
                        ? value => updateTransferProduct(id, { qty_fulfilled: Number(value) })
                        : undefined
                    }
                  />
                )}
                {(isFulfilled || isReceived) && (
                  <TableCell
                    value={{
                      type: "number",
                      initial: qty_received,
                      positiveOnly: true,
                    }}
                    onChange={
                      !isReceived ? value => updateTransferProduct(id, { qty_received: Number(value) }) : undefined
                    }
                  />
                )}

                {!isReceived && (
                  <TableRowActions
                    actions={[
                      {
                        id: "delete",
                        iconBefore: <FaTrash />,
                        onClick: () => deleteTransferProducts([id]),
                        tabIndex: "-1",
                      },
                    ]}
                  />
                )}
              </TableRow>
            )
          )}
        </>
      )}
    />
  );
};

export { TransferTable };
